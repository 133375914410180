import React, { useContext } from "react";
import { ChessContext } from "./ChessContext";
import DataAccess from "./DataAccess";

export const ImportPgn = (props) => {
  const chessContext = useContext(ChessContext);
  let fileInput = React.createRef();

  const doImport = () => {
    if (fileInput.current.files.length === 0) return;

    let file = fileInput.current.files[0];
    chessContext.setLoading(true);

    DataAccess.importPgnFile(file)
      .then(
        (response) => {
          if (response.status === 400) {
            window.alert(
              "Import failed. Please make sure the pgn file is in correct format."
            );
          }
        },
        (e) => {
          window.alert("Import failed.");
        }
      )
      .then(() => {
        chessContext.setLoading(false);
      });
  };

  return (
    <form>
      <div className="modal-body">
        <h4 className="mb-4">Import PGN file</h4>
        <div className="form-group">
          <input
            type="file"
            className="form-control-file"
            id="formControlFile1"
            ref={fileInput}
          />
        </div>
        <div className="alert alert-warning mt-3">
          <small>
            Importing may take a few minutes depends on the number of games in
            the pgn file. You can continue using the site while we import your
            file in the background.
          </small>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-secondary" onClick={props.onClose}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary" onClick={doImport}>
          Import
        </button>
      </div>
    </form>
  );
};
