import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { ProfileInline } from "./ProfileInline";

export class TopNav extends React.PureComponent {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-sm navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <img
              src="/img/king.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt=""
              loading="lazy"
            />
            <span> Private Chess Position Repository</span>
          </Link>

          <div className="ml-auto">
            <div className="my-lg-0 d-flex align-items-center">
              <a
                className="mr-3 d-none d-lg-inline-block"
                href="mailto:privatechesspositionrepository@gmail.com"
              >
                <i className="material-icons">feedback</i>
              </a>
              <ProfileInline></ProfileInline>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
