import React, { useState } from "react";

const Note = (props) => {
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <a key={i} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  let noteData = urlify(props.note.data);

  return (
    <div className="list-group-item pl-0 pr-0 showOnHoverParent">
      <div className="d-flex w-100 justify-content-between">
        {/* <h5 className="mb-1">List group item heading</h5> */}
        <small className="text-muted">{props.note.author}</small>
        {/* <small className="text-muted">3 days ago</small> */}
      </div>
      <p className="mb-1">{noteData}</p>
      <button
        type="button"
        className="close showOnHoverChild text-danger deleteButton"
        aria-label="Close"
        onClick={props.onDelete}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export const NewNote = (props) => {
  const [value, setValue] = useState("");

  const itemChanged = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="list-group-flush">
      <div className="list-group-item pl-0 pr-0">
        <div className="d-block">
          <small className="text-muted">New private note</small>
        </div>
        <form className="d-flex">
          <textarea
            value={value}
            onChange={itemChanged}
            className="form-control flex-grow-1"
            rows="2"
          ></textarea>

          <button
            className="btn btn-primary ml-2 align-self-end"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (value)
                props.addNote({ data: value }).then(() => setValue(""));
            }}
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export const NotesList = (props) => {
  let noteslistUI = null;
  if (props.notes && props.notes.length > 0) {
    noteslistUI = props.notes.map((note) => (
      <Note note={note} key={note.id} onDelete={() => props.deleteNote(note)} />
    ));
  } else {
    noteslistUI = (
      <center className="text-muted mt-3">
        There are no notes for this position
      </center>
    );
  }

  return <div className="notesList list-group-flush">{noteslistUI}</div>;
};
