import React, { useState } from "react";

const Comment = (props) => {
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <a key={i} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="list-group-item pl-0 pr-0 showOnHoverParent">
      <div className="d-flex w-100 justify-content-between">
        <small className="text-muted">{props.comment.publicName}</small>
        <div className="d-flex">
          <buton
            className="btn btn-transp btn-small d-flex"
            onClick={() => props.vote(props.comment, true)}
          >
            <i className="material-icons mr-2">thumb_up_alt</i>
            <span>{props.comment.thumbsUp}</span>
          </buton>
          <buton
            className="btn btn-transp btn-small d-flex"
            onClick={() => props.vote(props.comment, false)}
          >
            <i className="material-icons mr-2">thumb_down_alt</i>
            <span>{props.comment.thumbsDown}</span>
          </buton>
          <small className="text-muted ml-3">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(new Date(props.comment.modifiedTime))}
          </small>
        </div>
      </div>
      <p className="mb-1">{urlify(props.comment.data)}</p>
    </div>
  );
};

export const NewComment = (props) => {
  const [value, setValue] = useState("");

  const itemChanged = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="list-group-flush">
      <div className="list-group-item pl-0 pr-0">
        <div className="d-block">
          <small className="text-muted">
            {" "}
            New Comment guidelines: <br />
            1. No spamming, advertising, duplicate, or gibberish posts. <br />
            2. No obscene, racist, sexist, or profane language.
            <br />
            3. No trolling and systematic personal attacks against other
            members.
            <br />
            4. No cyberstalking or malicious posting of negative or private
            information of members.
          </small>
        </div>
        <form className="d-flex">
          <textarea
            value={value}
            onChange={itemChanged}
            className="form-control flex-grow-1"
            rows="2"
          ></textarea>

          <button
            className="btn btn-primary ml-2 align-self-end"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              if (value) props.addComment(value).then(() => setValue(""));
            }}
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export const CommentList = (props) => {
  let noteslistUI = null;
  if (props.comments && props.comments.length > 0) {
    noteslistUI = props.comments.map((comment) => (
      <Comment comment={comment} key={comment.id} vote={props.vote} />
    ));
  } else {
    noteslistUI = (
      <center className="text-muted mt-3">
        No comments for this position. Be the first one to share your knowledge.
      </center>
    );
  }

  return <div className="notesList list-group-flush">{noteslistUI}</div>;
};
