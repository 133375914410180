import React from "react";

export const Checkbox = (props) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={props.value || false}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.checked);
          }
        }}
      />
      <label className="form-check-label">{props.label}</label>
    </div>
  );
};
