//import React from "react";
//import { FeedList } from "./FeedList";

//export const Home = () => {
//  return (
//    <div>
//      <h3>Feed</h3>
//      <div>
//        <FeedList />
//      </div>
//    </div>
//  );
//};


import React from "react";
import ChessBoard from "./ChessBoard";
import { DefaultStartFen } from "./ChessContext";
export const Home = () => {
    return (
        <div>
            <div className="jumbotron">
                <h4 className="display-6">
                    Welcome to Private Chess Position Repository
                </h4>
            </div>
            {/* <div>
        <ChessBoard
          name="1"
          fen={DefaultStartFen}
          dropOffBoard="trash"
          orientation={"white"}
          onChange={() => {}}
          arrows={[{ from: "a2", to: "a4" }]}
          interactive={true}
        />
      </div> */}
        </div>
    );
};