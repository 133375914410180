import React from "react";
import StaticUtils from "./StaticUtils";

export default class ChessBoard extends React.Component {
  board = null;
  boardAcquireTimeout = null;
  fen = null;
  orientation = "white";
  overlayEl = null;
  SQUARE_SIZE = 10;
  boardEl = null;
  interactive = false;
  annotations = [];
  nextMoveBy = "white";

  componentDidMount() {
    if (!this.props.name) throw "Chessboard should have a name property.";
    this.interactive = this.props.interactive || false;

    this.boardAcquireTimeout = setTimeout(() => {
      this.boardEl = document.getElementById(this.props.name);

      if (this.board == null && this.boardEl) {
        this.overlayEl = this.boardEl.parentElement.getElementsByClassName(
          "boardOverlay"
        )[0];

        const config = {
          draggable: this.interactive,
          orientation: this.props.orientation || "white",
          dropOffBoard: this.props.dropOffBoard || "trash",
          onDrop: this.onDrop,
          sparePieces: this.props.editMode,
        };

        this.board = window.Chessboard(this.props.name, config);
        this.resizeBoard();
        this.drawOverlays();
        this.handleMouseEvents();

        this.set(this.props.fen, config.orientation);

        clearTimeout(this.boardAcquireTimeout);

        window.addEventListener("resize", this.resizeBoard);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeBoard);
  }

  dragStartSquare = null;
  handleMouseEvents() {
    const isRightClick = (e) => {
      var isRightMB = false;
      if ("which" in e)
        // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
        isRightMB = e.which == 3;
      else if ("button" in e)
        // IE, Opera
        isRightMB = e.button == 2;

      return isRightMB;
    };

    if (this.interactive === true) {
      this.boardEl.addEventListener(
        "contextmenu",
        (e) => {
          e.preventDefault();
          return false;
        },
        { capture: true }
      );

      this.boardEl.addEventListener(
        "mousedown",
        (e) => {
          if (!isRightClick(e)) return;
          this.dragStartSquare = e.target.getAttribute("data-square");

          e.preventDefault();
          return false;
        },
        { capture: true }
      );

      this.boardEl.addEventListener(
        "mouseup",
        (e) => {
          if (!isRightClick(e)) return;
          var endSquare = e.target.getAttribute("data-square");
          if (this.dragStartSquare != null && endSquare) {
            if (this.props.onAnnotate) {
              this.props.onAnnotate(this.dragStartSquare, endSquare);
            }
          }
          this.dragStartSquare = null;
          e.preventDefault();
          return false;
        },
        { capture: true }
      );
    }
  }

  resetOverlays() {
    this.buildOverlay();
  }

  drawOverlays() {
    this.resetOverlays();
    if (!this.props.editMode) this.drawPlayingSide();

    var arrowList = this.annotations || [];

    arrowList.forEach((element) => {
      this.addArrowAnnotation(element.from, element.to);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.set(nextProps.fen, nextProps.orientation);
    this.annotations = nextProps.annotations;
    if (this.boardEl) {
      this.drawOverlays();
    }

    return false;
  }

  resizeBoard = () => {
    this.board.resize();
    this.calculateSquareSize();
    this.drawOverlays();
  };

  calculateSquareSize() {
    var containerWidth = this.boardEl.clientWidth;

    // defensive, prevent infinite loop
    if (!containerWidth || containerWidth <= 0) {
      return 0;
    }

    // pad one pixel
    var boardWidth = containerWidth - 1;

    while (boardWidth % 8 !== 0 && boardWidth > 0) {
      boardWidth--;
    }

    this.SQUARE_SIZE = boardWidth / 8;

    this.overlayEl.style.width = this.SQUARE_SIZE * 8 + "px";
    this.overlayEl.style.height = this.SQUARE_SIZE * 8 + "px";
  }

  set(fen, orientation) {
    if (this.board) {
      if (orientation != this.orientation) {
        this.orientation = orientation;
        this.board.orientation(orientation);
      }

      if (fen != this.fen) {
        this.fen = fen;
        if (!fen) {
          this.board.clear();
          this.nextMoveBy = "white";
        } else {
          this.board.position(fen, false);
          this.nextMoveBy = StaticUtils.getNextMoveSide(fen);
        }
      }
    }
  }

  render() {
    return (
      <div className="boardContainer">
        <svg className="boardOverlay"></svg>
        <div id={this.props.name}></div>
      </div>
    );
  }

  onMoveEnd = (oldP, newP) => {
    if (this.props.onMoveEnd) {
      this.props.onMoveEnd(this.board.fen());
    }
  };

  onDrop = (source, target, piece, newPos, oldPos, orientation) => {
    if (this.props.onChange) {
      return this.props.onChange(
        source,
        target,
        piece,
        newPos,
        oldPos,
        orientation,
        window.Chessboard.objToFen(newPos)
      );
    }
  };

  componentWillUnmount() {
    if (this.boardAcquireTimeout) {
      clearTimeout(this.boardAcquireTimeout);
    }
  }

  addArrowAnnotation(source, target) {
    const SQUARE_SIZE = this.SQUARE_SIZE;

    var groupEl = this.overlayEl.querySelector(".group-" + source);
    if (groupEl == null) {
      groupEl = this.createSvgEl("g", {
        class: "overlayGroup" + " group-" + source,
      });
      this.overlayEl.append(groupEl);
    }

    var pathEl = this.overlayEl.querySelector(
      "g.group-" + source + " > path.square-" + target
    );
    if (pathEl == null) {
      var config = {
        class: "overlayArrow" + " square-" + target,
        d: this.computePath(source, target),
        "stroke-linecap": "round",
        "stroke-width": SQUARE_SIZE / 6,
      };
      if (source !== target) {
        Object.assign(config, {
          "marker-end": "url(#endtriangle)",
          "stroke-width": SQUARE_SIZE / 3,
        });
      }
      var pathEl = this.createSvgEl("path", config);
      groupEl.append(pathEl);
    }
  }

  drawPlayingSide() {
    const SQUARE_SIZE = this.SQUARE_SIZE;
    const WIDTH = SQUARE_SIZE * 8;
    var groupEl = this.createSvgEl("g", {
      class: "playingSides",
    });
    this.overlayEl.append(groupEl);

    var v = this.nextMoveBy == "white" ? WIDTH : 0;
    if (this.orientation == "black") {
      v = v == 0 ? WIDTH : 0;
    }
    var config = {
      class: "playingSideIndicator",
      d: "M 0 " + v + " H " + WIDTH,

      "stroke-width": SQUARE_SIZE / 10,
      stroke: this.nextMoveBy,
    };

    var pathEl = this.createSvgEl("path", config);
    groupEl.append(pathEl);
  }

  removeArrowAnnotation(source, target) {
    this.overlayEl
      .querySelector(".group-" + source + " > path.square-" + target)
      .remove();
  }

  computePath(s1, s2) {
    const SQUARE_SIZE = this.SQUARE_SIZE;
    const COLUMNS = "abcdefgh".split("");
    function tristate(a, b) {
      if (a < b) return -0.25;
      if (a > b) return +0.25;
      return 0;
    }

    var start = { x: COLUMNS.indexOf(s1[0]), y: parseInt(s1[1], 10) - 1 };
    var end = { x: COLUMNS.indexOf(s2[0]), y: parseInt(s2[1], 10) - 1 };

    if (this.orientation == "white") {
      start.y = 7 - start.y;
      end.y = 7 - end.y;
    } else {
      start.x = 7 - start.x;
      end.x = 7 - end.x;
    }

    var dist = {
      x: Math.abs(start.x - end.x),
      y: Math.abs(start.y - end.y),
    };
    var corner; // Point of the dog-leg for knight moves
    var epsilon; // To adjust the target coords to take account of the arrowhead.

    if (dist.x != 0 && dist.y != 0 && dist.x != dist.y) {
      // Knight move; Calculate a corner point for the path, such that
      // the path dog-legs first along the long side, then short.
      if (dist.x > dist.y) {
        corner = { x: end.x, y: start.y };
        epsilon = { x: 0, y: tristate(start.y, end.y) };
      } else {
        corner = { x: start.x, y: end.y };
        epsilon = { x: tristate(start.x, end.x), y: 0 };
      }
    } else {
      epsilon = {
        x: tristate(start.x, end.x),
        y: tristate(start.y, end.y),
      };
    }

    if (s1 === s2) {
      var mx = SQUARE_SIZE * (start.x + 0.5);
      var my = SQUARE_SIZE * (start.y + 0.5);
      var r = SQUARE_SIZE / 3;
      return ["M", mx, my - r, "a", r, r, "0 1 0 0.0001 0 z"].join(" ");
    } else {
      var path = [
        "M",
        SQUARE_SIZE * (start.x + 0.5),
        SQUARE_SIZE * (start.y + 0.5),
      ];
      if (corner !== undefined) {
        path.push(
          "L",
          SQUARE_SIZE * (corner.x + 0.5),
          SQUARE_SIZE * (corner.y + 0.5)
        );
      }
      path.push(
        "L",
        SQUARE_SIZE * (end.x + epsilon.x + 0.5),
        SQUARE_SIZE * (end.y + epsilon.y + 0.5)
      );

      return path.join(" ");
    }
  }

  createSvgEl(tag, attr) {
    var svgEl = document.createElementNS("http://www.w3.org/2000/svg", tag);
    for (var key in attr) {
      svgEl.setAttribute(key, attr[key]);
    }
    return svgEl;
  }

  buildOverlay() {
    var defsEl = this.createSvgEl("defs", {});
    var arrowHead = this.createSvgEl("marker", {
      id: "endtriangle",
      viewBox: "0 0 10 10",
      refX: "0",
      refY: "5",
      markerUnits: "strokeWidth",
      markerWidth: "3",
      markerHeight: "2",
      orient: "auto",
    });
    arrowHead.append(
      this.createSvgEl("path", {
        d: "M 0 0 L 5 5 L 0 10 Z",
      })
    );
    defsEl.append(arrowHead);

    this.overlayEl.textContent = "";
    this.overlayEl.append(defsEl);
  }
}
