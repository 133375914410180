import { useState, useCallback } from "react";
import { parse, stringify } from "qs";

const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};

const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = parse(queryString.replace(/^\?/, ""));
  values[key] = value;
  const newQsValue = stringify(values, { encode: false });

  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = parse(queryString.replace(/^\?/, ""));
  return decodeURIComponent(values[key]);
};

function useQueryString(key, initialValue) {
  let x = getQueryStringValue(key);
  if (x && x !== "undefined") {
    initialValue = x;
  }

  const [value, setValue] = useState(initialValue);
  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
