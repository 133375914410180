import React, { useState, useContext, useEffect } from "react";
import ChessBoard from "./ChessBoard";
import { useHistory } from "react-router-dom";
import { Checkbox } from "./Checkbox";
import DataAccess from "./DataAccess";
import { SearchResults } from "./SearchResults";
import { ChessContext, myRepoConst } from "./ChessContext";
import { ANALYZE_PATH } from "./Layout";
import StaticUtils from "./StaticUtils";

export const BoardEditor = (props) => {
  const chessContext = useContext(ChessContext);
  const [fen, setFen] = useState(chessContext.fen);

  const [toPlay, setToPlay] = useState("w");
  const [woo, setWoo] = useState(true);
  const [wooo, setWooo] = useState(true);
  const [boo, setBoo] = useState(true);
  const [booo, setBooo] = useState(true);
  const [publicRepos, setPublicRepos] = useState(null);

  useEffect(() => {
    DataAccess.getPublicRepos().then((repos) => {
      setPublicRepos(repos.items);
    });
  }, []);

  const clearBoard = (e) => {
    e.preventDefault();
    setFen("");
  };

  const doSearch = (type) => {
    DataAccess.doSearch(fen, type, JSON.parse(chessContext.searchRepo)).then(
      (res) => {
        let results = Object.assign({ fen: fen, type: type }, res);
        chessContext.setSearchResults(results);
      },
      (err) => {
        window.alert("Search failed.");
      }
    );
  };

  let history = useHistory();
  const onAnalysisBoardClick = () => {
    history.push(ANALYZE_PATH);
    chessContext.setFen(fen);
  };

  const getCastlingString = (woo, wooo, boo, booo) => {
    let castling =
      (woo ? "K" : "") +
      (wooo ? "Q" : "") +
      (boo ? "k" : "") +
      (booo ? "q" : "");
    castling = castling === "" ? "-" : castling;
    return castling;
  };

  const boardUpdated = (
    source,
    target,
    piece,
    newPos,
    oldPos,
    orientation,
    newFen
  ) => {
    if (fen !== newFen) {
      doUpdate(woo, wooo, boo, booo, toPlay, newFen);
    }
  };

  const doUpdate = (woo, wooo, boo, booo, toPlay, fen) => {
    let finalFen =
      StaticUtils.toShortFen(fen, true) +
      " " +
      toPlay +
      " " +
      getCastlingString(woo, wooo, boo, booo) +
      " - 0 1";

    setWoo(woo);
    setWooo(wooo);
    setBoo(boo);
    setBooo(booo);
    setToPlay(toPlay);
    setFen(finalFen);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4">
          <ChessBoard
            name="editor"
            fen={fen}
            onChange={boardUpdated}
            editMode={true}
            orientation={chessContext.orientation}
            interactive={true}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-8">
          <h4>Options</h4>
          <div>
            <button className="btn btn-info mr-1" onClick={clearBoard}>
              Clear Board
            </button>
            <button
              className="btn btn-info"
              onClick={() =>
                chessContext.setOrientation(
                  chessContext.orientation === "white" ? "black" : "white"
                )
              }
            >
              Flip Board
            </button>
          </div>
          <div className="form-group d-flex flex-column w-100 mt-3">
            <label htmlFor="fenDisplay">FEN</label>
            <input
              type="text"
              className="form-control w-100"
              id="fenDisplay"
              value={fen}
              onChange={(e) => {
                doUpdate(
                  woo,
                  wooo,
                  boo,
                  booo,
                  toPlay,
                  decodeURIComponent(e.target.value)
                );
              }}
            />
          </div>
          <div>
            <form>
              <div className="form-group">
                <select
                  className="form-control"
                  value={toPlay}
                  onChange={(e) =>
                    doUpdate(woo, wooo, boo, booo, e.target.value, fen)
                  }
                >
                  <option value="w">White to play</option>
                  <option value="b">Black to play</option>
                </select>
                <h6 className="mt-3">Castling</h6>
                <Checkbox
                  label="White O-O"
                  value={woo}
                  onChange={(v) => doUpdate(v, wooo, boo, booo, toPlay, fen)}
                />
                <Checkbox
                  label="White O-O-O"
                  value={wooo}
                  onChange={(v) => doUpdate(woo, v, boo, booo, toPlay, fen)}
                />
                <Checkbox
                  label="Black O-O"
                  value={boo}
                  onChange={(v) => doUpdate(woo, wooo, v, booo, toPlay, fen)}
                />
                <Checkbox
                  label="Black O-O-O"
                  value={booo}
                  onChange={(v) => doUpdate(woo, wooo, boo, v, toPlay, fen)}
                />
                <h6 className="mt-3">Repository</h6>
                <select
                  className="form-control"
                  value={chessContext.searchRepo}
                  onChange={(e) => chessContext.setSearchRepo(e.target.value)}
                >
                  <option value={JSON.stringify(myRepoConst)}>
                    My Current Repo
                  </option>
                  {publicRepos &&
                    publicRepos.map((r, i) => (
                      <option value={JSON.stringify(r)} key={i}>
                        {r.repository}
                      </option>
                    ))}
                </select>
              </div>
            </form>
          </div>
          <div>
            <button
              className="btn btn-info mr-1"
              onClick={onAnalysisBoardClick}
            >
              Analysis Board
            </button>
            <button
              className="btn btn-info mr-1"
              onClick={() => {
                doSearch("endgame");
              }}
            >
              Endgame search
            </button>
            <button
              className="btn btn-info"
              onClick={() => {
                doSearch("pattern");
              }}
            >
              Pattern search
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <h6>Search Results</h6>
          <SearchResults />
        </div>
      </div>
    </>
  );
};
