import React, { useState, useContext } from "react";
import { Modal } from "./Modal";
import DataAccess from "./DataAccess";
import { ChessContext } from "./ChessContext";

export const ExportPosition = (props) => {
  const chessContext = useContext(ChessContext);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [description, setDescription] = useState("");

  const exportPosition = () => {
    chessContext.setLoading(true);

    DataAccess.exportPosition(props.fen, description)
      .then(
        () => {},
        (e) => {
          window.alert("Export failed");
        }
      )
      .then(() => {
        chessContext.setLoading(false);
        setDescription("");
        setDialogVisible(false);
      });
  };

  return (
    <div>
      <button
        className="btn btn-info ml-auto"
        onClick={() => setDialogVisible(true)}
      >
        Export Position
      </button>
      <Modal
        title="Export Position"
        show={dialogVisible}
        onClose={() => setDialogVisible(false)}
      >
        <form>
          <div class="modal-body">
            <div class="form-group">
              <label htmlFor="exportDescription">Description</label>
              <textarea
                className="form-control w-100"
                id="exportDescription"
                rows="2"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              ></textarea>
            </div>

            <div className="alert alert-warning mt-3">
              <small>
                This will export current and all consecutive positions.
                Exporting may take few hours to complete. You can continue using
                the site while we export your positions in the background.
              </small>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                exportPosition();
              }}
            >
              Export
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
