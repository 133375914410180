import React, { useState, useEffect } from "react";
import DataAccess from "./DataAccess";
import { Modal } from "./Modal";
import StaticUtils from "./StaticUtils";

export const GameList = (props) => {
  const [searchResult, setSearchResult] = useState({});
  const [selectedGameItem, setSelectedGameItem] = useState(null);

  const loadResults = (contToken) => {
    DataAccess.getGames(props.fen, contToken).then(
      (res) => {
        let results = Object.assign({}, res);
        results.items = [
          ...((contToken && searchResult.items) || []),
          ...res.items,
        ];
        setSearchResult(results);
      },
      (err) => {
        window.alert("Failed to get game list for this position.");
      }
    );
  };

  const loadMore = () => {
    loadResults(searchResult.continuationToken);
  };

  const onResultClick = (item) => {
    setSelectedGameItem(item);
  };

  const downloadPgn = () => {
    DataAccess.getGame(selectedGameItem.id).then((gameData) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        new Blob([gameData.completePgn], {
          type: "text/plain",
        })
      );
      let filename =
        selectedGameItem.whitePlayer + " vs " + selectedGameItem.blackPlayer;
      a.setAttribute("download", filename + ".pgn");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setSelectedGameItem(null);
    });
  };

  const importGame = () => {
    DataAccess.importGame(selectedGameItem.id).then(() => {
      window.alert("Import started. It may take a few minutes.");
      setSelectedGameItem(null);
    });
  };

  useEffect(() => {
    setSearchResult({});
    loadResults(null);
  }, [props.fen]);

  if (searchResult.items == null) {
    return <span>Loading...</span>;
  }

  let rows = null;
  if (searchResult.items && searchResult.items.length > 0) {
    rows = searchResult.items.map((item, i) => {
      return (
        <tr
          onClick={() => onResultClick(item)}
          key={item.id}
          className="gameListItem"
        >
          <td>
            {item.whitePlayer} ({item.whiteElo})
          </td>
          <td>
            {item.blackPlayer} ({item.blackElo})
          </td>
          <td>{StaticUtils.gameResultToText(item.result)}</td>
          <td>{item.year}</td>
        </tr>
      );
    });
  } else {
    rows = (
      <center className="text-muted mt-3">
        There are no games for this position.
      </center>
    );
  }

  return (
    <div>
      <Modal
        show={selectedGameItem !== null}
        showCloseButton={true}
        title="Game details"
        onClose={() => setSelectedGameItem(null)}
      >
        <div className="modal-body">
          {selectedGameItem && (
            <table className="gameDetailsView table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Event</td>
                  <td>{selectedGameItem.event}</td>
                </tr>
                <tr>
                  <td>Site</td>
                  <td>{selectedGameItem.site}</td>
                </tr>
                <tr>
                  <td>Year</td>
                  <td>{selectedGameItem.year}</td>
                </tr>
                <tr>
                  <td>Round</td>
                  <td>{selectedGameItem.round}</td>
                </tr>
                <tr>
                  <td>White player</td>
                  <td>{selectedGameItem.whitePlayer}</td>
                </tr>
                <tr>
                  <td>White Elo</td>
                  <td>{selectedGameItem.whiteElo}</td>
                </tr>
                <tr>
                  <td>Black player</td>
                  <td>{selectedGameItem.blackPlayer}</td>
                </tr>
                <tr>
                  <td>Black Elo</td>
                  <td>{selectedGameItem.blackElo}</td>
                </tr>
                <tr>
                  <td>Result</td>
                  <td>
                    {StaticUtils.gameResultToText(selectedGameItem.result)}
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <div className="modal-footer">
            <button className="btn btn-primary" onClick={importGame}>
              Import
            </button>
            <button className="btn btn-primary" onClick={downloadPgn}>
              Download
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                props.onOpenGameClick(selectedGameItem);
                setSelectedGameItem(null);
              }}
            >
              Open
            </button>
          </div>
        </div>
      </Modal>

      <table className="gameList table table-sm">
        <thead>
          <th>White player</th>
          <th>Black player</th>
          <th>Result</th>
          <th>Year</th>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      {searchResult.continuationToken !== null && (
        <button className="btn btn-warning btn-sm mt-2" onClick={loadMore}>
          Load more...
        </button>
      )}
    </div>
  );
};
