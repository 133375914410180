import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ChessContext } from "./ChessContext";
import { SelectRepo } from "./SelectRepo";
import { ANALYZE_PATH } from "./Layout";

export const MenuBar = (props) => {
  const chessContext = useContext(ChessContext);
  const [showRepoSelection, setShowRepoSelection] = useState(false);

  return (
    <>
      <ul className="nav menubar">
        <li className="nav-item">
          <Link className="nav-link" to={ANALYZE_PATH}>
            Analyze
          </Link>
        </li>
        <li className="nav-item d-none d-md-block">
          <Link className="nav-link" to="/importpgn">
            Import PGN
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/search">
            Search
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/play">
            Training Arena
          </Link>
        </li>

        <li className="nav-item ml-auto">
          <button
            className="btn btn-sm nav-link d-inline-flex align-items-center"
            onClick={(e) => {
              e.preventDefault();
              setShowRepoSelection(true);
            }}
          >
            <i className="material-icons mr-1">topic</i> {chessContext.repo}
          </button>
        </li>
      </ul>

      {showRepoSelection && (
        <SelectRepo
          onClose={() => {
            setShowRepoSelection(false);
          }}
        />
      )}
    </>
  );
};
