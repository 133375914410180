import React, { useContext } from "react";
import { ANALYZE_PATH } from "./Layout";
import DataAccess from "./DataAccess";
import { ChessContext } from "./ChessContext";
import { useHistory } from "react-router";

export const SearchResults = (props) => {
  const history = useHistory();
  const chessContext = useContext(ChessContext);

  const loadMore = (searchResult) => {
    chessContext.setLoading(true);
    DataAccess.doSearch(
      searchResult.fen,
      searchResult.type,
      JSON.parse(chessContext.searchRepo),
      searchResult.continuationToken
    )
      .then(
        (res) => {
          let results = Object.assign(
            { fen: searchResult.fen, type: searchResult.type },
            res
          );

          results.items = [...searchResult.items, ...res.items];

          chessContext.setSearchResults(results);
        },
        (err) => {
          window.alert("Search failed.");
        }
      )
      .then(() => {
        chessContext.setLoading(false);
      });
  };

  const onResultClick = (item) => {
    history.push(ANALYZE_PATH);
    chessContext.setFen(item.position);
  };

  let rows = null;
  if (
    chessContext.searchResults &&
    chessContext.searchResults.items.length > 0
  ) {
    rows = chessContext.searchResults.items.map((item, i) => {
      return (
        <button
          onClick={() => onResultClick(item)}
          key={item.position}
          className="list-group-item list-group-item-action"
        >
          {item.position}
        </button>
      );
    });
  } else {
    rows = (
      <center className="text-muted mt-3">There are no search results.</center>
    );
  }

  return (
    <div className="searchResults mb-5">
      <ul className="list-group">{rows}</ul>
      {chessContext.searchResults &&
        chessContext.searchResults.continuationToken !== null && (
          <button
            disabled={chessContext.isLoading}
            className="btn btn-warning btn-sm mt-2"
            onClick={() => loadMore(chessContext.searchResults)}
          >
            Load more...
          </button>
        )}
    </div>
  );
};
