export default class StaticUtils {
  static INPUT_ELEMENTS = ["INPUT", "TEXTAREA", "SELECT"];
  static IsInputElement(element) {
    if (StaticUtils.INPUT_ELEMENTS.indexOf(element.nodeName) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  static fenEquals(fen1, fen2) {
    if (fen1 === fen2) return true;
    if (!fen1 || !fen2) return false;

    let fenRegex = /^\S* . \S*/;
    let m1 = fen1.match(fenRegex);
    let m2 = fen2.match(fenRegex);

    if (m1.length === 0 || m2.length === 0) return false;

    return m1[0] === m2[0];
  }

  static toShortFen(fen, onlyPositions = false) {
    let fenRegex = onlyPositions === true ? /^\S*/ : /^\S* . \S*/;
    let m1 = fen.match(fenRegex);
    if (m1.length === 0) return null;
    return m1[0];
  }

  static gameResultToText(result) {
    switch (result) {
      case 0:
        return "White wins";
      case 1:
        return "Black wins";
      case 2:
        return "Draw";
      case 3:
        return "Unknown";
    }
  }

  static getNextMoveSide(fen) {
    var matches = fen.match(/^\S*\s(.)\s/);
    if (matches.length >= 2 && matches[1] == "b") {
      return "black";
    }
    return "white";
  }
}
