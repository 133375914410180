import React from "react";
import "./App.css";
import { ChessProvider } from "./ChessContext";
import { Layout } from "./Layout";

function App() {
  return (
    <ChessProvider>
      <Layout></Layout>
    </ChessProvider>
  );
}

export default App;
