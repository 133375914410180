import React, { useState, useEffect } from "react";
import StaticUtils from "./StaticUtils";

export const MoveList = (props) => {
  const [selectedMove, setSelectedMove] = useState(null);

  const itemClicked = (move) => {
    if (props.onMoveSelected) {
      props.onMoveSelected(move);
    }
  };

  const onDelete = (move) => {
    if (
      window.confirm(
        "Are you sure you want to delete this move and all its consecutive moves/positions?"
      )
    ) {
      if (props.onDelete) {
        props.onDelete(move);
      }
    }
  };

  const moveSelection = (direction) => {
    if (selectedMove) {
      let index = props.moves.indexOf(selectedMove);
      let newIndex = index + direction;
      if (newIndex >= 0 && newIndex < props.moves.length) {
        setSelectedMove(props.moves[newIndex]);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (StaticUtils.IsInputElement(e.target)) return;

    // 38 - up, 40 down
    if (e.keyCode === 38) {
      moveSelection(-1);
    } else if (e.keyCode === 40) {
      moveSelection(1);
    } else if (e.keyCode === 39 && selectedMove) {
      itemClicked(selectedMove);
    }
  };

  useEffect(() => {
    if (props.showSelection !== false) {
      document.addEventListener("keyup", handleKeyPress);
      return function cleanup() {
        document.removeEventListener("keyup", handleKeyPress);
      };
    }
  });

  useEffect(() => {
    setSelectedMove(
      props.moves && props.moves.length > 0 ? props.moves[0] : null
    );
  }, [props.moves]);

  const listItems =
    props.moves &&
    props.moves.map((move) => (
      <li key={move.move.toString()} className="showOnHoverParent d-flex">
        <button
          onClick={() => itemClicked(move)}
          className={
            "btn " +
            (props.showSelection !== false && selectedMove === move
              ? "active"
              : "") +
            " w-100"
          }
        >
          {move.move}
        </button>
        {props.showDelete === true && (
          <button
            type="button"
            className="close showOnHoverChild text-danger deleteButton"
            aria-label="Close"
            onClick={() => onDelete(move)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </li>
    ));

  return <ul className="list-unstyled">{listItems}</ul>;
};
