import React from "react";

export class LoadingSign extends React.Component {
  render() {
    // if (!this.props.isLoading)

    return (
      <div className="progress-horizontal">
        <div
          className={
            this.props.isLoading ? "bar-horizontal active" : "bar-horizontal"
          }
        ></div>
      </div>
    );
  }
}
