import React, { useState, useEffect } from "react";

export const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState("");

  const setInitialTab = () => setActiveTab(props.children[0].props.label);

  useEffect(() => {
    setInitialTab();
  }, []);

  let tabs = props.children.map((child) => (
    <button
      onClick={() => setActiveTab(child.props.label)}
      className={
        "flex-fill " + (child.props.label === activeTab ? "active" : "")
      }
      key={child.props.label}
    >
      {child.props.label}
    </button>
  ));

  return (
    <div
      className={
        "tabView d-flex flex-column h-100 shadow-sm" +
        (props.fillVertical === true ? " position-absolute" : "")
      }
    >
      <div className="tabGroup d-flex flex-column">
        <div className="d-flex">{tabs}</div>
      </div>
      <div
        className={
          "d-flex flex-column flex-grow-1" +
          (props.fillVertical === true ? " overflow-auto" : "")
        }
      >
        {props.children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};
