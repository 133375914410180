import React, { useContext } from "react";
import { ChessContext } from "./ChessContext";
import { Login } from "./Login";

export const ProfileInline = (props) => {
  const chessContext = useContext(ChessContext);

  if (chessContext.user != null) {
    return (
      <div className="profileInline">
        <span className="username d-none d-lg-inline-block">
          {chessContext.user.username}
        </span>
        <img
          className="profilePic"
          src={chessContext.user.profilePic}
          alt="profile"
          loading="lazy"
        ></img>
      </div>
    );
  } else {
    return <Login />;
  }
};
