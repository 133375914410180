import React from "react";

export const Modal = (props) => {
  if (props.show !== true) return null;

  return (
    <div className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title && props.title}</h5>
            {props.showCloseButton !== false && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  props.onClose();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};
