import { myRepoConst } from "./ChessContext";

export default class DataAccess {
  static baseUrl = "https://chesspositions.com/api/";
  //static baseUrl = "https://localhost:5001/api/";
  static positionApiPath = "position/";
  static notesApiPath = "notes/";
  static commentsApiPath = "comment/";
  static repository = "MyWhite";

  static getAuthToken = null;

  static deleteMove = (move) => {
    return fetch(
      DataAccess.baseUrl +
        this.positionApiPath +
        "move/" +
        "?repository=" +
        this.repository,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
        body: JSON.stringify(move),
      }
    );
  };

  static addNote = (note, fen) => {
    return fetch(
      DataAccess.baseUrl +
        this.notesApiPath +
        "?position=" +
        encodeURIComponent(fen) +
        "&repository=" +
        this.repository,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
        body: JSON.stringify(note),
      }
    ).then((res) => res.json());
  };

  static addComment = (comment, fen) => {
    return fetch(DataAccess.baseUrl + this.commentsApiPath, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: DataAccess.getAuthToken(),
      },
      body: JSON.stringify({ data: comment, position: fen }),
    }).then((res) => res.json());
  };

  static voteComment = (fen, id, voteUp = true) => {
    let votePath = voteUp === true ? "ThumbsUp" : "ThumbsDown";
    return fetch(
      DataAccess.baseUrl +
        this.commentsApiPath +
        votePath +
        "?position=" +
        encodeURIComponent(fen) +
        "&id=" +
        encodeURIComponent(id),
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    );
  };

  static getComments = (fen) => {
    return fetch(
      DataAccess.baseUrl +
        this.commentsApiPath +
        "?position=" +
        encodeURIComponent(fen),
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static deleteNote = (note, fen) => {
    return fetch(
      DataAccess.baseUrl +
        this.notesApiPath +
        "?position=" +
        encodeURIComponent(fen) +
        "&repository=" +
        this.repository +
        "&id=" +
        note.id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
        body: JSON.stringify(note),
      }
    );
  };

  static getPositionDetails = (position) => {
    return fetch(
      DataAccess.baseUrl +
        this.positionApiPath +
        "?position=" +
        encodeURIComponent(position) +
        "&repository=" +
        this.repository,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => {
      return res.status == 204 ? null : res.json();
    });

    //return stackablePromise;
  };

  static savePositionDetails = (position) => {
    return fetch(DataAccess.baseUrl + this.positionApiPath, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: DataAccess.getAuthToken(),
      },
      body: JSON.stringify(position),
    });
  };

  static promiseStack = [];

  static getGMMove = (position) => {
    return fetch(
      DataAccess.baseUrl +
        "RandomGMMove?position=" +
        encodeURIComponent(position) +
        "&repository=" +
        this.repository,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => (res.status === 200 ? res.json() : null));
  };

  static deletePosition = (fen) => {
    return fetch(
      DataAccess.baseUrl +
        this.positionApiPath +
        "?position=" +
        encodeURIComponent(fen) +
        "&repository=" +
        this.repository,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    );
  };

  static exportPosition = (position, description) => {
    return fetch(DataAccess.baseUrl + "publishposition", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: DataAccess.getAuthToken(),
      },
      body: JSON.stringify({
        Repository: this.repository,
        StartingPosition: position,
        Description: description,
      }),
    });
  };

  static importPosition = (fen, positionObj, completeRepo = false) => {
    return fetch(
      DataAccess.baseUrl +
        "publishedPosition/import?importCompleteRepository=" +
        completeRepo +
        "&ToRepository=" +
        this.repository +
        "&toPosition=" +
        encodeURIComponent(fen),
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
        body: JSON.stringify(positionObj),
      }
    );
  };

  static importPgnFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetch(
      DataAccess.baseUrl + "importPGN?repository=" + this.repository,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
        body: formData,
      }
    );
  };

  static getPublishedPositions = (fen) => {
    return fetch(
      DataAccess.baseUrl +
        "publishPosition?position=" +
        encodeURIComponent(fen),
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static getBranch = (fen, dir) => {
    let action = dir === "rev" ? "PreviousBranch" : "NextBranch";
    return fetch(
      DataAccess.baseUrl +
        this.positionApiPath +
        action +
        "?repository=" +
        this.repository +
        "&position=" +
        encodeURIComponent(fen),
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static getRepos = () => {
    return fetch(DataAccess.baseUrl + "repository", {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: DataAccess.getAuthToken(),
      },
    }).then((res) => res.json());
  };

  static addRepo = (repoName, orientation) => {
    return fetch(DataAccess.baseUrl + "repository", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: DataAccess.getAuthToken(),
      },
      body: JSON.stringify({
        Repository: repoName,
        WhiteOrientation: orientation === "white",
      }),
    });
  };

  static getPublicRepos = () => {
    return fetch(DataAccess.baseUrl + "PublicRepository", {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: DataAccess.getAuthToken(),
      },
    }).then((res) => res.json());
  };

  static doSearch = (fen, type, repo, contToken) => {
    let repoName = this.repository;

    let apiPath = type === "endgame" ? "EndGame" : "PatternRecognition";
    if (repo.repository !== myRepoConst.repository) {
      apiPath = "Public" + apiPath;
      repoName = repo.repository;
    }

    return fetch(
      DataAccess.baseUrl +
        "search/" +
        apiPath +
        "?position=" +
        encodeURIComponent(fen) +
        "&repository=" +
        repoName +
        (repo.repository !== myRepoConst.repository
          ? "&user=" + repo.createrName
          : "") +
        (contToken ? "&continuationToken=" + contToken : ""),
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static getDanglingPositions = (contToken) => {
    return fetch(
      DataAccess.baseUrl +
        this.positionApiPath +
        "dangling?" +
        "repository=" +
        this.repository +
        (contToken ? "&continuationToken=" + contToken : ""),
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static getGames = (fen, contToken) => {
    return fetch(
      DataAccess.baseUrl +
        "games?position=" +
        encodeURIComponent(fen) +
        "&repository=" +
        this.repository +
        (contToken ? "&continuationToken=" + contToken : ""),
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static getFeed = (contToken) => {
    return fetch(
      DataAccess.baseUrl +
        "feed" +
        (contToken ? "&continuationToken=" + contToken : ""),
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
      }
    ).then((res) => res.json());
  };

  static likeDislikeFeed = (feedId, likeOrDislike) => {
    let path = likeOrDislike === 1 ? "FeedLike" : "FeedDislike";
    return fetch(DataAccess.baseUrl + path + "?feedId=" + feedId, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: DataAccess.getAuthToken(),
      },
    });
  };

  static getGame = (id) => {
    return fetch(DataAccess.baseUrl + "game?gameid=" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: DataAccess.getAuthToken(),
      },
    }).then((res) => res.json());
  };

  static importGame = (id) => {
    return fetch(
      DataAccess.baseUrl +
        "importGame?gameid=" +
        id +
        "&repository=" +
        this.repository,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: DataAccess.getAuthToken(),
        },
      }
    );
  };
}
