import React, { useEffect, useState } from "react";
import DataAccess from "./DataAccess";
import { Modal } from "./Modal";

export const PublishedPositionsList = (props) => {
    const [positions, setPositions] = useState([]);
    const [importingPosition, setImportingPosition] = useState(null);
    const [importRepo] = useState(false);

    const importPosition = (positionObj, fullRepo = false) => {
        DataAccess.importPosition(props.fen, positionObj, fullRepo)
            .then(
                () => { },
                (err) => {
                    window.alert("Failed to import position.");
                }
            )
            .then(() => {
                setImportingPosition(null);
            });
    };

    useEffect(() => {
        if (props.fen) {
            DataAccess.getPublishedPositions(props.fen).then(
                (result) => {
                    setPositions(result.items);
                },
                (err) => { }
            );
        } else {
            setPositions([]);
        }
    }, [props.fen]);

    if (!positions) return null;
    let pp = positions.map((pos) => {
        let moveString = !pos.moveList ? (
            <> </>
        ) : (
            pos.moveList.map((m) => (
                <button
                    key={m.move}
                    className="btn mr-1"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        props.onMoveClick(m);
                    }}
                >
                    {m.move}
                </button>
            ))
        );

        return (
            <div
                key={pos.publicName + pos.repository}
                className="d-flex flex-column mb-1 bg-light p-2"
            >
                <div className="d-flex w-100">
                    <small className="text-muted">{pos.publicName}</small>
                    <small className="badge badge-info ml-auto">{pos.totalImport}</small>
                </div>
                <div className="text-nowrap text-truncate">{pos.description}</div>
                <div className="mt-1 d-flex">
                    <div>{moveString}</div>
                    <button
                        className="ml-auto btn btn-link"
                        onClick={() => setImportingPosition(pos)}
                    >
                        Import
                    </button>
                </div>
            </div>
        );
    });

    const onModalClose = () => {
        setImportingPosition(null);
    };

    return (
        <div>
            <Modal
                show={importingPosition != null}
                title="Import positions"
                onClose={onModalClose}
            >
                <div className="modal-body">
                    <div className="alert alert-warning mt-3">
                        <small>
                            The time it takes to import depends on the number of positions in the repository,
                            but you can still use the site while we import the data in the background.

                            As for the importing rules, if the repository is focused on white positions, it will import all black moves for each position.
                            However, if there is more than one white position, the import will stop as it is up to the user to decide their preferred move or position.
                        </small>
                    </div>
                    <button
                        className="bn btn-primary mt-2"
                        onClick={() => importPosition(importingPosition, importRepo)}
                    >
                        Import
                    </button>
                </div>
            </Modal>

            <div className="publishedPositions">{pp}</div>
        </div>
    );
};
