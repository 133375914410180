import React, { useState, useContext, useEffect } from "react";
import ChessBoard from "./ChessBoard";
import DataAccess from "./DataAccess";
import { ChessContext, DefaultStartFen } from "./ChessContext";

export const PlayArena = () => {
  const chessContext = useContext(ChessContext);

  const resetPosition = (e) => {
    e.preventDefault();
    resetToFen(DefaultStartFen);
  };

  const resetToFen = (fen) => {
    let res = chessContext.chess.loadFen(fen);
    if (res) {
      chessContext.setFen(fen);
      return true;
    } else {
      window.alert("Failed to load fen.");
      return false;
    }
  };

  const undoLastMove = () => {
    let newFen = chessContext.chess.undoLastMove();
    if (newFen !== null) {
      chessContext.setFen(newFen);
      return true;
    }
    return false;
  };

  const doMove = (source, target, promoPiece) => {
    var moveResult = chessContext.chess.move(source, target, promoPiece);
    if (moveResult) {
      chessContext.setFen(moveResult.newPos);
      savePositionDetails(
        moveResult.newPos,
        moveResult.oldPos,
        moveResult.move
      );
    }
  };

  const onMove = (
    source,
    target,
    piece,
    newPos,
    oldPos,
    orientation,
    newFen,
    promoPiece
  ) => {
    let playersPiece =
      piece[0] === (chessContext.repoOrientation === "white" ? "w" : "b");
    if (!playersPiece) {
      return "snapback";
    }

    var move = chessContext.chess.move(source, target);
    if (move === "snapback") return move;

    chessContext.setFen(move.newPos);

    savePositionDetails(move.newPos, move.oldPos, move.move);
    return true;
  };

  const savePositionDetails = (fen, prevFen, move) => {
    if (!fen) return;

    let position = {
      Position: fen,
      LastMove: move,
      Repository: chessContext.repo,
      PreviousPosition: prevFen,
    };

    DataAccess.savePositionDetails(position);
  };

  const checkAndMakeGMMove = () => {
    if (!chessContext.fen) return;
    let matches = chessContext.fen.match(/^\S* (.)/);
    let toPlay = matches[1] === "w" ? "white" : "black";

    if (toPlay === chessContext.repoOrientation) return;

    DataAccess.getGMMove(chessContext.fen).then((gmMove) => {
      if (gmMove) {
        console.log("GM move " + gmMove.move);
        doMove(gmMove.move);
      } else {
        console.log("no GM moves found");
        chessContext.chess.nextBestMove().then((m) => {
          doMove(m.substring(0, 2), m.substring(2, 4), "q");
        });
      }
    });
  };

  useEffect(() => {
    checkAndMakeGMMove();
  }, [chessContext.fen, chessContext.repo]);

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="d-flex">
            <div className="flex-grow-1">
              <ChessBoard
                name="1"
                fen={chessContext.fen}
                dropOffBoard="trash"
                orientation={chessContext.orientation}
                onChange={onMove}
                editMode={false}
                interactive={true}
              />
              <div className="d-flex mt-1 w-100 bg-light">
                <button className="btn" onClick={resetPosition} title="Reset">
                  <i className="material-icons">album</i>
                </button>
                <button className="btn" onClick={undoLastMove} title="Back">
                  <i className="material-icons flip-horizontal">play_arrow</i>
                </button>

                <div className="d-flex ml-auto">
                  <button
                    className="btn"
                    onClick={() =>
                      chessContext.setOrientation(
                        chessContext.orientation === "white" ? "black" : "white"
                      )
                    }
                    title="Flip orientation of board"
                  >
                    <i className="material-icons">loop</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
