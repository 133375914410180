import React, { useState, useContext, useEffect } from "react";
import ChessBoard from "./ChessBoard";
import { NotesList, NewNote } from "./NotesList";
import { MoveList } from "./MoveList";
import DataAccess from "./DataAccess";
import { ChessContext, DefaultStartFen } from "./ChessContext";
import { ExportPosition } from "./ExportPostion";
import { PublishedPositionsList } from "./PublishedPositionList";
import StaticUtils from "./StaticUtils";
import { SearchResults } from "./SearchResults";
import { Tabs } from "./Tabs";
import { DanglingPositions } from "./DanglingPositions";
import { Modal } from "./Modal";
import { CommentList, NewComment } from "./Comments";
import { GameList } from "./GameList";
import { ChessGame } from "./ChessSession";
import { GameMovesList } from "./GameMoves";
import Dropdown from "react-bootstrap/Dropdown";

export const AnalyzePage = () => {
  const chessContext = useContext(ChessContext);

  const [notes, setNotes] = useState(null);
  const [comments, setComments] = useState([]);
  const [nextMoves, setNextMoves] = useState([]);
  const [prevMoves, setPrevMoves] = useState([]);
  const [error, setError] = useState(null);
  const [promotionCallback, setPromotionCallback] = useState(null);
  const [canUndo, setCanUndo] = useState(false);
  const [tmpFen, setTmpFen] = useState(null);
  const [annotations, _setAnnotations] = useState([]);

  const [lastMoveName, setLastMoveName] = useState(null);

  const addNote = (note) => {
    chessContext.setLoading(true);

    return DataAccess.addNote(note, chessContext.fen).then(
      (newNote) => {
        setNotes((notes && [...notes, newNote]) || [newNote]);
        chessContext.setLoading(false);
      },
      (error) => {
        chessContext.setLoading(false);
        setError("Failed to save note.");
      }
    );
  };

  const deleteNote = (note) => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      chessContext.setLoading(true);

      DataAccess.deleteNote(note, chessContext.fen).then(
        () => {
          let newNotes = [...notes];
          newNotes.splice(newNotes.indexOf(note), 1);
          setNotes(newNotes);
          chessContext.setLoading(false);
        },
        (error) => {
          chessContext.setLoading(false);
          setError("Failed to delete note.");
        }
      );
    }
  };

  const onMoveDelete = (move) => {
    DataAccess.deleteMove(move).then(
      () => {
        var array = [...nextMoves];
        var index = array.indexOf(move);
        if (index !== -1) {
          array.splice(index, 1);
          setNextMoves(array);
        }
      },
      (error) => {
        setError("Failed to delete moves");
      }
    );
  };

  const undoLastMove = () => {
    let newFen = chessContext.chess.undoLastMove();
    if (newFen !== null) {
      chessContext.setFen(newFen);
      return true;
    } else if (prevMoves && prevMoves.length > 0) {
      doMove(prevMoves[0], "prev");
    }
    return false;
  };

  const savePositionDetails = (fen, prevFen, move) => {
    if (!fen) return Promise.resolve();

    let position = {
      Position: fen,
      LastMove: move,
      Repository: chessContext.repo,
      PreviousPosition: prevFen,
    };

    return DataAccess.savePositionDetails(position);
  };

  const doMove = (move, dir) => {
    if (dir === "prev") {
      if (move.move === lastMoveName) {
        undoLastMove();
      } else {
        resetToFen(move.fromPosition);
      }
    } else {
      var moveResult = chessContext.chess.move(move.move);

      if (moveResult && moveResult != "snapback") {
        setTmpFen(chessContext.chess.fen());
        clearFenSpecificState();
        savePositionDetails(
          moveResult.newPos,
          moveResult.oldPos,
          moveResult.move
        ).then(() => {
          setTmpFen(null);
          chessContext.setFen(moveResult.newPos);
        });
      }
    }
  };

  const getPromotionPiece = (source, target, piece) => {
    return new Promise((resolve) => {
      var source_rank = source.substring(2, 1);
      var target_rank = target.substring(2, 1);

      if (
        piece[1] === "P" &&
        ((source_rank === "7" && target_rank === "8") ||
          (source_rank === "2" && target_rank === "1"))
      ) {
        setPromotionCallback(() => (r) => {
          resolve(r);
          setPromotionCallback(null);
        });
      } else {
        resolve("q");
      }
    });
  };

  const onMove = (source, target, piece) => {
    if (chessContext.chess.isValidMove(source, target) === false) {
      return "snapback";
    }

    getPromotionPiece(source, target, piece).then((promPiece) => {
      var move = chessContext.chess.move(source, target, promPiece);
      setTmpFen(chessContext.chess.fen());
      clearFenSpecificState();
      savePositionDetails(move.newPos, move.oldPos, move.move).then(() => {
        setTmpFen(null);
        chessContext.setFen(move.newPos);
      });
    });

    return true;
  };

  const resetPosition = (e) => {
    e.preventDefault();
    resetToFen(DefaultStartFen);
  };

  const moveToBranch = (e, dir) => {
    chessContext.setLoading(true);
    DataAccess.getBranch(chessContext.fen, dir)
      .then(
        (result) => {
          if (dir === "rev") {
            if (result.item1 == null) {
              resetToFen(DefaultStartFen);
            } else {
              let prevFen = result.item1.position;
              let newFen = chessContext.chess.moveBackToFen(prevFen);
              chessContext.setFen(newFen);
            }
          } else {
            if (result.item1 == null) return;
            let finalFen = result.item1.position;
            let newFen = chessContext.chess.moveUpToFen(
              chessContext.fen,
              result.item2,
              finalFen
            );

            chessContext.setFen(newFen);
          }
        },
        (error) => {
          setError("Failed to load last branch.");
        }
      )
      .then(() => {
        chessContext.setLoading(false);
      });
  };

  const importGame = (id) => {
    DataAccess.importGame(id).then(() => {
      window.alert("Import started. It may take a few minutes.");
    });
  };

  const deletePosition = () => {
    if (
      window.confirm(
        "Are you sure you want to delete this position and all its consecutive moves/positions?"
      )
    ) {
      chessContext.setLoading(true);
      DataAccess.deletePosition(chessContext.fen).then(
        () => {
          if (!undoLastMove()) {
            resetToFen(DefaultStartFen);
          }
        },
        (err) => {
          window.alert("Delete position failed.");
        }
      );
    }
  };

  const handleKeyPress = (e) => {
    if (StaticUtils.IsInputElement(e.target)) return;

    // 37 - left, 39 right
    if (e.keyCode === 37) {
      e.stopPropagation();
      undoLastMove();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return function cleanup() {
      document.removeEventListener("keyup", handleKeyPress);
    };
  });

  const resetToFen = (fen) => {
    let res = chessContext.chess.loadFen(fen);
    if (res) {
      chessContext.setFen(fen);
      return true;
    } else {
      window.alert("Failed to load fen.");
      return false;
    }
  };

  useEffect(() => {
    getPositionDetails(chessContext.fen);
  }, [chessContext.fen, chessContext.repo]);

  useEffect(() => {
    setCanUndo(
      chessContext.chess.canUndo() ||
        (prevMoves != null && prevMoves.length > 0)
    );
  }, [chessContext.fen, prevMoves]);

  const clearFenSpecificState = () => {
    setNotes(null);
    setNextMoves(null);
    setPrevMoves(null);
    setComments(null);
    setAnnotations([]);
  };

  const getPositionDetails = (fen) => {
    if (!fen) return;

    clearFenSpecificState();

    chessContext.setLoading(true);

    DataAccess.getPositionDetails(fen).then(
      (result) => {
        if (result === null) return;
        setNotes(result.notes);
        setNextMoves(result.nextMoves || []);
        setPrevMoves(result.prevMoves || []);
        setLastMoveName(chessContext.chess.getLastMove());

        chessContext.setLoading(false);
      },
      (error) => {
        setError("Failed to load data for fen " + fen + ". " + error);
        chessContext.setLoading(false);
      }
    );

    DataAccess.getComments(fen).then((result) => {
      if (result === null) return;
      setComments(result.items || []);
    });
  };

  const addComment = (comment) => {
    return DataAccess.addComment(comment, chessContext.fen).then((c) => {
      setComments([c, ...comments]);
    });
  };

  const doVote = (comment, voteUp) => {
    DataAccess.voteComment(chessContext.fen, comment.id, voteUp).then(() => {
      if (voteUp) {
        comment.thumbsUp++;
      } else {
        comment.thumbsDown++;
      }
      setComments([...comments]);
    });
  };

  const openGame = (gameMeta) => {
    chessContext.setOpenGame(null);
    DataAccess.getGame(gameMeta.id).then((gameData) => {
      let game = new ChessGame();
      let res = game.load(gameData.completePgn);
      if (res === true) {
        game.gameInfo = gameData;
        chessContext.setOpenGame(game);
      } else {
        window.alert("Failed to open game. Invalid format.");
      }
    });
  };

  const setAnnotations = (newAnnotations) => {
    _setAnnotations(newAnnotations);
  };

  const onAnnotate = (source, target) => {
    var existingIndex = annotations.findIndex(
      (e) => e.from === source && e.to === target
    );
    if (existingIndex != -1) {
      var newAnnotations = annotations;
      newAnnotations.splice(existingIndex, 1);
      setAnnotations([...newAnnotations]);
      return;
    }

    setAnnotations([...annotations, { from: source, to: target }]);
  };

  if (error != null) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  return (
    <div>
      <Modal
        show={promotionCallback !== null}
        showCloseButton={false}
        title="Choose promotion piece"
      >
        <form>
          <div className="modal-body">
            <center>
              <div className="form-group">
                <button
                  className="btn mr-1"
                  onClick={() => promotionCallback("r")}
                >
                  <img
                    src={"img\\chesspieces\\wikipedia\\wR.png"}
                    alt="rook"
                  ></img>
                </button>
                <button
                  className="btn mr-1"
                  onClick={() => promotionCallback("n")}
                >
                  <img
                    src={"img\\chesspieces\\wikipedia\\wN.png"}
                    alt="knight"
                  ></img>
                </button>
                <button
                  className="btn mr-1"
                  onClick={() => promotionCallback("b")}
                >
                  <img
                    src={"img\\chesspieces\\wikipedia\\wB.png"}
                    alt="bishop"
                  ></img>
                </button>
                <button
                  className="btn mr-1"
                  onClick={() => promotionCallback("q")}
                >
                  <img
                    src={"img\\chesspieces\\wikipedia\\wQ.png"}
                    alt="queen"
                  ></img>
                </button>
              </div>
            </center>
          </div>
        </form>
      </Modal>
      <div className="row">
        <div className="col-md-8 mb-2">
          <div className="d-flex">
            <div className="moveList text-right mr-2 d-none d-md-block">
              <h6>Prev</h6>
              <MoveList
                moves={prevMoves}
                onMoveSelected={(move) => doMove(move, "prev")}
                showDelete={false}
                showSelection={false}
              />
            </div>
            <div className="flex-grow-1">
              <ChessBoard
                name="1"
                fen={tmpFen || chessContext.fen}
                dropOffBoard="trash"
                orientation={chessContext.orientation}
                onChange={onMove}
                interactive={true}
                annotations={annotations}
                onAnnotate={onAnnotate}
              />
              <div className="d-flex mt-1 w-100 bg-light">
                <button className="btn" onClick={resetPosition} title="Reset">
                  <i className="material-icons">album</i>
                </button>
                <button
                  className="btn"
                  onClick={(e) => moveToBranch(e, "rev")}
                  title="Last branch"
                >
                  <i className="material-icons">skip_previous</i>
                </button>
                <button
                  className="btn"
                  onClick={undoLastMove}
                  title="Back"
                  hidden={canUndo == false}
                >
                  <i className="material-icons flip-horizontal">play_arrow</i>
                </button>
                <button
                  className="btn"
                  onClick={(e) => moveToBranch(e, "fwd")}
                  title="Next branch"
                >
                  <i className="material-icons">skip_next</i>
                </button>

                <div className="d-flex ml-auto">
                  <Dropdown>
                    <Dropdown.Toggle variant="Warning" id="dropdown-basic">
                      <span>
                        <i className="material-icons">delete_forever</i>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setAnnotations([])}>
                        Clear arrows
                      </Dropdown.Item>
                      <Dropdown.Item onClick={deletePosition}>
                        Delete position
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    className="btn"
                    onClick={() =>
                      chessContext.setOrientation(
                        chessContext.orientation === "white" ? "black" : "white"
                      )
                    }
                    title="Flip orientation of board"
                  >
                    <i className="material-icons">loop</i>
                  </button>
                  <div className="d-none">
                    <ExportPosition fen={chessContext.fen} />
                  </div>
                </div>
              </div>
            </div>
            <div className="moveList ml-2 d-none d-md-block">
              <h6>Next</h6>
              <MoveList
                moves={nextMoves}
                onMoveSelected={(move) => doMove(move, "next")}
                onDelete={onMoveDelete}
                showDelete={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 align-self-stretch">
          <Tabs fillVertical={true}>
            <div label="Annotations">
              <div className="d-flex flex-column h-100">
                <div className="flex-grow-1 overflow-auto">
                  <div className="ml-2 mr-2">
                    <NotesList notes={notes} deleteNote={deleteNote} />
                  </div>
                </div>
                <div className="bg-light">
                  <div className="ml-2 mr-2">
                    <NewNote addNote={addNote} />
                  </div>
                </div>
              </div>
            </div>
            <div label="Search Results">
              <SearchResults items={chessContext.searchResults}></SearchResults>
            </div>

            <div label="Dangling">
              <DanglingPositions />
            </div>
          </Tabs>
        </div>
      </div>

      {chessContext.openGame && (
        <div className="row mt-5">
          <h4>
            {chessContext.openGame.gameInfo.year} :{" "}
            {chessContext.openGame.gameInfo.whitePlayer}{" "}
            <span className="text-muted">vs</span>{" "}
            {chessContext.openGame.gameInfo.blackPlayer}{" "}
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                chessContext.setOpenGame(null);
              }}
            >
              Close
            </button>
            <button
              className="btn btn-sm btn-info ml-1"
              onClick={() => {
                importGame(chessContext.openGame.gameInfo.id);
              }}
            >
              Import
            </button>
          </h4>
          <GameMovesList
            game={chessContext.openGame}
            onMoveClick={resetToFen}
          />
        </div>
      )}

      <div className="row mt-5">
        <div className="col">
          <Tabs>
            <div label="Published Repositories">
              <PublishedPositionsList
                fen={tmpFen || chessContext.fen}
                onMoveClick={(move) => {
                  doMove(move, "next");
                }}
              />
            </div>

            <div label="Games">
              <div className="m-2">
                <GameList fen={chessContext.fen} onOpenGameClick={openGame} />
              </div>
            </div>

            <div label="Comments">
              <div className="m-2">
                <NewComment addComment={addComment} />
                <CommentList comments={comments} vote={doVote} />
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
