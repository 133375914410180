import React from "react";

export const GameMovesList = (props) => {
  const onMoveClick = (move) => {
    if (props.onMoveClick) {
      props.onMoveClick(move.fen);
    }
  };

  return (
    <div>
      <div>
        {props.game.getMoves().map((move, index) => (
          <div className="d-inline-block mb-2">
            <button
              onClick={() => {
                onMoveClick(move);
              }}
              className={"btn btn-sm"}
              style={{ margin: "1px" }}
              key={index}
            >
              <span className="text-muted">
                {index % 2 == 0 ? (index + 2) / 2 + "." : ""}
              </span>{" "}
              {move.san}
            </button>
            {index % 2 == 1 && <span className="mr-2"> </span>}
          </div>
        ))}
      </div>
    </div>
  );
};
