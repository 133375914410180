import React, { useContext } from "react";
import { TopNav } from "./TopNav";
import { LoadingSign } from "./LoadingSign";
import { useHistory, Switch, Route } from "react-router";
import { ChessContext } from "./ChessContext";
import { AnalyzePage } from "./AnalyzePage";
import { Login } from "./Login";
import { MenuBar } from "./MenuBar";
import { BoardEditor } from "./BoardEditor";
import { PlayArena } from "./PlayArena";
import { ImportPgn } from "./ImportPgn";
import { Home } from "./Home";

export const ANALYZE_PATH = "/analyze";
export const PLAY_PATH = "/play";

export const Layout = (props) => {
  const chessContext = useContext(ChessContext);
  let history = useHistory();

  function navigate(to) {
    history.push(to);
  }

  return (
    <div>
      <TopNav nav={navigate} />
      <MenuBar />
      <LoadingSign isLoading={chessContext.isLoading} />
      <div className="mt-3"></div>
      <div className="container-lg">
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Home}
            isAuth={chessContext.user !== null}
          />
          <PrivateRoute
            path="/search"
            component={BoardEditor}
            isAuth={chessContext.user !== null}
          />
          <PrivateRoute
            path="/importpgn"
            component={ImportPgn}
            isAuth={chessContext.user !== null}
          />
          <PrivateRoute
            path={ANALYZE_PATH}
            component={AnalyzePage}
            isAuth={chessContext.user !== null}
          />

          <PrivateRoute
            path={PLAY_PATH}
            component={PlayArena}
            isAuth={chessContext.user !== null}
          />
        </Switch>
      </div>
    </div>
  );
};

const PrivateRoute = ({
  component: Component,
  isAuth: isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <div>
          <h4 className="mb-2">Plese sign in to use PCPR</h4>
          <Login />
        </div>
      )
    }
  />
);
