import React, { useContext, useState, useEffect } from "react";
import { ChessContext, DefaultStartFen } from "./ChessContext";
import { Modal } from "./Modal";
import DataAccess from "./DataAccess";

export const SelectRepo = (props) => {
  const chessContext = useContext(ChessContext);
  const [repos, setRepos] = useState([]);
  const [newRepoName, setNewRepoName] = useState("");
  const [orientation, setOrientation] = useState("white");

  const repoSelected = (r) => {
    DataAccess.repository = r.repository;
    chessContext.setRepo(r.repository);
    chessContext.setRepoOrientation(
      r.whiteOrientation === true ? "white" : "black"
    );

    chessContext.setFen(DefaultStartFen);
    props.onClose();
  };

  const addRepo = (e) => {
    e.preventDefault();
    if (newRepoName) {
      DataAccess.addRepo(newRepoName, orientation).then(
        () => {
          repoSelected({ repository: newRepoName });
        },
        (err) => {
          window.alert("Failed to add new repository.");
        }
      );
    }
  };

  useEffect(() => {
    DataAccess.getRepos().then(
      (result) => {
        setRepos(result.items);
      },
      (err) => {}
    );
  }, []);

  let repoList = repos.map((r) => (
    <button
      className="list-group-item list-group-item-action"
      key={r.repository}
      onClick={(e) => {
        repoSelected(r);
      }}
    >
      {r.repository}
    </button>
  ));

  return (
    <Modal show={true} title="Select Repository" onClose={props.onClose}>
      <div className="modal-body">
        <div className="list-group">{repoList}</div>
      </div>
      <div className="modal-body">
        <form>
          <h6>Add New</h6>
          <div className="form-row">
            <div className="form-group col-sm-8">
              <label htmlFor="newRepoInput" className="font-smaller">
                Name
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="newRepoInput"
                placeholder="Repository name"
                value={newRepoName}
                onChange={(e) =>
                  setNewRepoName(e.target.value.replace(":", ""))
                }
              />
            </div>
            <div className="form-group col-sm-4">
              <label htmlFor="newRepoOrientation" className="font-smaller">
                Orientation
              </label>
              <select
                id="newRepoOrientation"
                className="form-control form-control-sm"
                value={orientation}
                onChange={(e) => setOrientation(e.target.value)}
              >
                <option value="white">White</option>
                <option value="black">Black</option>
              </select>
            </div>
          </div>
          <button
            className="btn btn-outline-primary btn-sm"
            type="submit"
            onClick={addRepo}
          >
            Add
          </button>
        </form>
      </div>
    </Modal>
  );
};
