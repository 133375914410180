import Chess from "chess.js";
import StaticUtils from "./StaticUtils";
import { DefaultStartFen } from "./ChessContext";

export class ChessSession {
  initialFen = DefaultStartFen;
  chess = new Chess(this.initialFen);
  stockfish = window.STOCKFISH();
  bestMovePromiseResolver = null;
  bestMoveCallback = null;

  constructor(fen) {
    this.loadFen(fen);
    this.stockfish.onmessage = this.chessEngineResponse;
  }

  fen() {
    return this.chess.fen();
  }

  getPiece(square) {
    return this.chess.get(square);
  }

  loadFen(fen) {
    if (StaticUtils.fenEquals(this.chess.fen(), fen)) return true;
    this.initialFen = fen;
    var res = this.chess.load(fen);
    this.nextBestMove().then(this.registerNextBestMove);
    return res;
  }

  moves() {
    var result = [];
    this.chess.history({ verbose: true }).forEach((move) => {
      result.push(move);
    });
    return result;
  }

  isValidMove(source, target) {
    var tmpChess = new Chess(this.chess.fen());
    var move = tmpChess.move({ from: source, to: target, promotion: "q" });

    return move != null;
  }

  registerNextBestMove = (m) => {
    console.log("next best move " + m);
    if (this.bestMoveCallback) {
      this.bestMoveCallback(m);
    }
  };

  subscribeToBestMoves(bestMovesCallback) {
    this.bestMoveCallback = bestMovesCallback;
  }

  nextBestMove() {
    return new Promise((resolve, reject) => {
      this.bestMovePromiseResolver = resolve;
      var moves = this.moves().map((m) => m.from + "" + m.to);
      this.requestNextBestMove(this.initialFen, moves);
    });
  }

  requestNextBestMove(fen, moves) {
    var postMessage = "position fen " + fen + " moves " + moves.join(" ");
    //console.log(postMessage);

    this.stockfish.postMessage(postMessage);
    this.stockfish.postMessage("go depth 10");
  }

  chessEngineResponse = (event) => {
    event = event.data ? event.data : event;
    //console.log(event);

    if (this.bestMovePromiseResolver != null && event.startsWith("bestmove")) {
      this.bestMovePromiseResolver(event.split(" ")[1]);
    }
  };

  move(source, target, promotionPiece = "q") {
    let oldPos = this.chess.fen();

    let move = null;

    if (target) {
      move = this.chess.move({
        from: source,
        to: target,
        promotion: promotionPiece,
      });
    } else {
      move = this.chess.move(source);
    }

    if (move == null) return "snapback";

    let history = this.chess.history();
    let lastMove = history[history.length - 1];
    let newPos = this.chess.fen();

    this.nextBestMove().then(this.registerNextBestMove);

    return {
      oldPos: oldPos,
      newPos: newPos,
      move: lastMove,
      from: move.from,
      to: move.to,
    };
  }

  moveBackToFen(fen) {
    let history = this.chess.history();
    this.chess = new Chess(this.initialFen);

    for (var i = 0; i < history.length; i++) {
      this.chess.move(history[i]);
      if (StaticUtils.fenEquals(fen, this.chess.fen())) {
        return fen;
      }
    }

    console.log("Could not find branch in previous moves. Loading fen.");
    let res = this.chess.load(fen);
    if (res !== null) {
      return fen;
    } else {
      return null;
    }
  }

  moveUpToFen(startFen, moves, finalFen) {
    if (!StaticUtils.fenEquals(this.chess.fen(), startFen)) {
      throw Error(
        "Start fen does not match current position. Cannot move to next branch."
      );
    }

    for (var i = 0; i < moves.length; i++) {
      let moveRes = this.chess.move(moves[i]);
      if (moveRes === null) {
        console.log(
          "Invalid move while navigating to next branch. Index:" +
            i +
            ":" +
            moves[i]
        );
        break;
      }
    }

    if (!StaticUtils.fenEquals(this.chess.fen(), finalFen)) {
      console.log(
        "Could not reach final fen by navigating to branch. Loading final fen directly."
      );
      this.chess.load(finalFen);
    }

    return finalFen;
  }

  undoLastMove() {
    let history = this.chess.history();

    if (history.length === 0) return null;

    this.chess = new Chess(this.initialFen);

    for (var i = 0; i < history.length - 1; i++) {
      this.chess.move(history[i]);
    }

    return this.chess.fen();
  }

  canUndo() {
    return this.chess.history.length > 1;
  }

  getLastMove() {
    let history = this.chess.history();
    return history.length > 0 ? history[history.length - 1] : null;
  }
}

export class ChessGame {
  chess = null;
  index = 0;
  gameInfo = undefined;
  moves = [];

  cleanPgn(pgn) {
    pgn = pgn.replace(/ ½-½$/, "");
    return pgn;
  }

  load(pgn) {
    pgn = this.cleanPgn(pgn);
    this.chess = new Chess();
    let res = this.chess.load_pgn(pgn, { sloppy: true });

    this.calcMoves();

    return res;
  }

  calcMoves() {
    let tmpChess = new Chess();
    this.chess.history({ verbose: true }).forEach((move) => {
      tmpChess.move(move);
      this.moves.push(Object.assign(move, { fen: tmpChess.fen() }));
    });
  }

  getMoves() {
    return this.moves;
  }
}
