import React, { createContext, useState, useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import useQueryString from "./useQueryString";
import { ChessSession } from "./ChessSession";

export const ChessContext = createContext();
export const DefaultStartFen =
  "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "4a9c2bc1-e9f5-4779-bab7-57863d9e1df3",
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
  },
});

appInsights.loadAppInsights();

export const myRepoConst = { repository: "__MYREPO" };

export const ChessProvider = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isSignedIn, setSignedIn] = useState(false);
  const [user, _setUser] = useState(null);
  const [repo, setRepo] = useState("MyWhite");
  const [repoOrientation, setRepoOrientation] = useState("white");
  const [searchResults, setSearchResults] = useState(null);
  const [fen, _setFen] = useQueryString("fen", DefaultStartFen);
  const [orientation, setOrientation] = useState("white");
  const [chess] = useState(() => new ChessSession(fen));
  const [openGame, setOpenGame] = useState();
  const [searchRepo, setSearchRepo] = useState(JSON.stringify(myRepoConst));

  const setFen = (fen) => {
    var res = chess.loadFen(fen);
    if (res) {
      _setFen(fen);
    }
    return res;
  };

  const setUser = (user) => {
    _setUser(user);
    appInsights.setAuthenticatedUserContext(user.username);
  };

  useEffect(() => {
    setOrientation(repoOrientation);
  }, [repoOrientation]);

  return (
    <ChessContext.Provider
      value={{
        isLoading,
        setLoading,
        isSignedIn,
        setSignedIn,
        user,
        setUser,
        repo,
        setRepo,
        repoOrientation,
        setRepoOrientation,
        searchResults,
        setSearchResults,
        fen,
        setFen,
        orientation,
        setOrientation,
        chess,
        openGame,
        setOpenGame,
        searchRepo,
        setSearchRepo,
        myRepoConst,
      }}
    >
      {props.children}
    </ChessContext.Provider>
  );
};
