import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ANALYZE_PATH } from "./Layout";
import DataAccess from "./DataAccess";
import { ChessContext } from "./ChessContext";

export const DanglingPositions = (props) => {
  const [searchResult, setSearchResult] = useState({});
  const history = useHistory();
  const chessContext = useContext(ChessContext);
  const loadMore = () => {
    DataAccess.getDanglingPositions(searchResult.continuationToken)
      .then(
        (res) => {
          let results = Object.assign({}, res);
          results.items = [...(searchResult.items || []), ...res.items];
          setSearchResult(results);
        },
        (err) => {
          window.alert("Failed to get dangling positions.");
        }
      )
      .then(() => {});
  };

  const onResultClick = (item) => {
    history.push(ANALYZE_PATH);
    chessContext.setFen(item.position);
  };

  useEffect(() => {
    loadMore();
  }, []);

  let rows = null;
  if (searchResult.items && searchResult.items.length > 0) {
    rows = searchResult.items.map((item, i) => {
      return (
        <button
          onClick={() => onResultClick(item)}
          key={item.position}
          className="list-group-item list-group-item-action"
        >
          {item.position}
        </button>
      );
    });
  } else {
    rows = (
      <center className="text-muted mt-3">
        There are no dangling positions.
      </center>
    );
  }

  return (
    <div className="searchResults mb-5">
      <ul className="list-group">{rows}</ul>
      {searchResult.continuationToken !== null && (
        <button className="btn btn-warning btn-sm mt-2" onClick={loadMore}>
          Load more...
        </button>
      )}
    </div>
  );
};
