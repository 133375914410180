import React, { useContext } from "react";
import { ChessContext } from "./ChessContext";
import DataAccess from "./DataAccess";

export const Login = (props) => {
  const chessContext = useContext(ChessContext);

  window.onSignIn = (googleUser) => {
    var profile = googleUser.getBasicProfile();

    DataAccess.getAuthToken = () => {
      return googleUser.getAuthResponse().id_token;
    };

    chessContext.setUser({
      username: profile.getName(),
      profilePic: profile.getImageUrl(),
    });
  };

  if (chessContext.user != null) {
    return null;
  }

  return (
    <div>
      <div className="g-signin2" data-onsuccess="onSignIn"></div>
    </div>
  );
};
